import React, { Suspense } from 'react';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import ErrorPage from './error-page';
import Layout from './core/Layout';

const Productos = React.lazy(() => import('./core/Productos'));
const Booking = React.lazy(() => import('./core/Booking'));
const ProductoDetail = React.lazy(() => import('./core/ProductoDetail'));
const Checkout = React.lazy(() => import('./core/Checkout'));
const Orden = React.lazy(() => import('./core/pago/Orden'));

const router = createBrowserRouter([
  {
    path: "/",
    element:  <Layout/>,
    errorElement: <ErrorPage />,
    children:[
      {
        key: 1,
        path: "products",
        element: <Suspense fallback={<div>...</div>}>
            <Productos />
        </Suspense>,
      },
      {
        key: 2,
        path: "booking/:productId/:servicio/:subservicio",
        element: <Suspense fallback={<div>...</div>}>
            <Booking />
        </Suspense>,
      },
      {
        key: 3,
        path: "producto-details/:productId",
        element: <Suspense fallback={<div>...</div>}>
            <ProductoDetail />
        </Suspense>,
      },
      {
        key: 4,
        path: "checkout",
        element: <Suspense fallback={<div>...</div>}>
            <Checkout />
        </Suspense>,
      },
      {
        key: 5,
        path: "orden",
        element: <Suspense fallback={<div>...</div>}>
            <Orden />
        </Suspense>,
      },
    ]
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
