const Constantes = {
    NOMBRECKUSUARIO: 'dfalk140',
    LOCALUSUARIO: 'usuariodumpster',
    ADMINUSUARIO: 'admindumpster',
    TOKENADMINUSUARIO: 'atkdm',
    LOCALSHOPCAR: 'shopcardst',
    LOCALPAGO: 'shoppagodst',
    LOCALCLIENTE: 'shoppcliente',
    estRegistro: [
        {label: 'Activo', value: 'ACT'},
        {label: 'Inactivo', value: 'INA'}
    ],
    estadoPago: ['PAGADA','PENDIENTE','ABONADA','DEVUELTA'],
    planes:{
        ILIMITADO: 'I',
        MARKET:'M'
    },
    tipousuario:{
        ADMINISTRADOR: 'A',
        SUCURSAL:'S',
        USUARIO:'U'
    },
    tiposervicio: {
        'garbage':'Garbage (C&D)',
        'recyclingsm':'Recycling (Scrap Metal)'
    },
    subserviciosName: {
        'delivery': 'Delivery',
        'swap': 'Swap',
        'pickup': 'Pick Up- No Fee',
        'booknow': 'Book Now',
        'requestquote': 'Request Quote'
    },
    subservicios: {
        'Delivery':{
            estilos:{
                background: '#c02929',
                borderColor: '#c02929',
                color: '#fff'
            },
            name: 'delivery'
        },
        'Swap':{
            estilos:{
                background: '#bbc1c5',
                borderColor: '#bbc1c5',
                color: '#fff'
            },
            name: 'swap'
        },
        'Pick Up- No Fee':{
            estilos: {
                background: '#7e96a6',
                borderColor: '#7e96a6',
                color: '#fff'
            },
            name: 'pickup'
        },
        'Book Now':{
            estilos: {
                background: '#c02929',
                borderColor: '#c02929',
                color: '#fff'
            },
            name: 'booknow'
        },
        'Request Quote':{
            estilos: {
                background: '#528bb0',
                borderColor: '#528bb0',
                color: '#fff'
            },
            name: 'requestquote'
        }
    },
    flooring: [
        'asphalt', 'concrete', 'dirt'
    ]
}

export default Constantes;